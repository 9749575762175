import React, { ReactElement } from "react";
import MainBanner from "@components/homewarranty/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import HomeWarrantyWrapper from "@components/layouts/homewarrantyWrapper";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const AboutUS = dynamic(() => import("@components/homewarranty/aboutUs"));
const Benefits = dynamic(() => import("@components/homewarranty/benefits"));
import WhatIsHomeWarranty from "@components/homewarranty/whatIsHomeWarranty";
import HowIsDifferent from "@components/homewarranty/howIsDifferent";
import NewJerseyDisclaimer from "@components/shared/newJerseyDisclaimer";
export default function WarrantyHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <HomeWarrantyWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <Benefits />
            <WhatIsHomeWarranty />
            <HowIsDifferent />
            <AboutUS />
            <NewJerseyDisclaimer backGround="#f1f3fc" />
        </HomeWarrantyWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
